import React from 'react';
import { Box, Typography } from '@mui/material';
import GradesTable from './GradesTable';
import Layout from '../../../components/Layout';
import useTeacherBenchmarksSubmissionList from '../Grade/grades-list-hook';

const Grade = () => {
  const {
    benchmarkSubmissionList,
    totalCount,
    loading,
    pagination,
    sort,
    order,
    handleSort,
    handleRedirection, handlePagination,
    selectedBenchmarkTypes, setSelectedBenchmarkTypes,
    selectedStatus, setSelectedStatus,
    handleApplyFilters,
    handleClearFilters,
    getBenchmarkTypeLabel,
    submissionQueueData,
  } = useTeacherBenchmarksSubmissionList();
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Typography variant="pageTitle">
          Grade
        </Typography>
        <Box display="flex" mt={2}>
          <GradesTable
            benchmarkSubmissionList={benchmarkSubmissionList}
            totalCount={totalCount}
            loading={loading}
            pagination={pagination}
            sort={sort}
            order={order}
            handleSort={handleSort}
            handleRedirection={handleRedirection}
            selectedBenchmarkTypes={selectedBenchmarkTypes}
            setSelectedBenchmarkTypes={setSelectedBenchmarkTypes}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            handleApplyFilters={handleApplyFilters}
            handleClearFilters={handleClearFilters}
            getBenchmarkTypeLabel={getBenchmarkTypeLabel}
            handlePagination={handlePagination}
            submissionQueueData={submissionQueueData}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Grade;
