import React from 'react';
import { Box, Step, StepConnector, StepLabel, Stepper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import DiagonalArrowIcon from '../Icons/DiagonalArrowIcon';
import { palette  } from '../../theme/palette';

interface Props {
  progressValue: number;
  steps: Array<string>;
}

interface ColoredStepIconProps {
  active: boolean;
  completed: boolean;
  icon: React.ReactNode;
}

const styles = {
  stepIcon: {
    backgroundColor: palette.customGrey.main,
    zIndex: 1,
    color: palette.customGrey.darkGrey,
    width: '40px',
    height: '40px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inactive: {
    border: `3px solid ${palette.customGrey.main}`,
    boxSizing: 'border-box',
  },
  active: {
    border: `3px solid ${palette.customBlue.primaryBlue}`,
    borderWidth: '1%',
    backgroundColor: palette.customWhite.main,
    color: palette.customBlue.primaryBlue,
  },
  completed: {
    backgroundColor: palette.customBlue.primaryBlue,
    color: palette.customWhite.main,
    fontSize: '1rem',
    borderRadius: '50%',
  },
};

const ColoredConnector = withStyles(() => ({
  alternativeLabel: {
    top: 16,
    left: 'calc(-50% + 13px)',
  },
  active: {
    '& $line': {
      backgroundColor: palette.customBlue.primaryBlue,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: palette.customBlue.primaryBlue,
    },
  },
  line: {
    marginLeft: 0.8,
    width: '100%',
    height: 6,
    border: 0,
    backgroundColor: palette.customGrey.main,
  },
}))(StepConnector);

const BoldTypography = styled(Typography)(() => ({
  fontWeight: 700,
  marginTop: -7,
}));

const ColoredStepIcon = (props: ColoredStepIconProps) => {
  const { active, completed, icon } = props;
  const icons: { [index: string]: React.ReactElement } = {
    1: <DiagonalArrowIcon sx={{ fontSize: '1rem' }} />,
    2: <RecordVoiceOverIcon sx={{ fontSize: '1.25rem' }} />,
    3: <CreateSharpIcon sx={{ fontSize: '1.25rem' }} />,
  };
  return (
    <Box
      sx={{
        ...styles.stepIcon, ...(active) && styles.active,
        ...(completed) && styles.completed,
        ...(!active && !completed) && styles.inactive,
      }}
    >
      {icons[String(icon)]}
    </Box>
  );
};

const ProgressBar = ({ steps, progressValue }: Props) => {
  return (
    <Box>
      <Stepper alternativeLabel activeStep={progressValue} connector={<ColoredConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColoredStepIcon}><BoldTypography>{label}</BoldTypography></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProgressBar;
