import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  Box,
  Typography
} from '@mui/material';
import {
  StyledTableCellFlyover,
  StyledTableContainerFlyover,
} from '../../../../components/Common/Common.styled';

// TODO handle the connection with graphql
interface Props {}

const NotStartedList = () => {
  return (
      <Box>
        <StyledTableContainerFlyover>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Avery Chen
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow><TableRow>
              <StyledTableCellFlyover>
                <Typography variant='secondaryLabel'>
                  Wesley Fantinel
                </Typography>
              </StyledTableCellFlyover>
            </TableRow><TableRow>
              <StyledTableCellFlyover>
                <Typography variant='secondaryLabel'>
                  Wesley Fantinel
                </Typography>
              </StyledTableCellFlyover>
            </TableRow>
              <TableRow>
                <StyledTableCellFlyover>
                  <Typography variant='secondaryLabel'>
                    Wesley Fantinel
                  </Typography>
                </StyledTableCellFlyover>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainerFlyover>
      </Box>
  );
};
export default NotStartedList;
