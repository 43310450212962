import React, {useState} from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import StyledTableContainer from '../../Assignments/AssignmentsTable/List.styled';
import TablePagination from '../../../components/TablePagination';
import NoResults from '../../../components/NoResults';
import WavingLadyFrame from '../../../components/Icons/WavingLadyFrame';
import { BenchmarkTeacherDtoSortType, OrderBy } from '../../../generated/graphql';
import ArrowDownIcon from '../../../components/Icons/ArrowDownIcon';
import ImageCardAssignment from '../../../components/ImageCardAssignment';
import Loader from '../../../components/Loader/loader';
import { StyledTableSort } from '../../../components/Common/Common.styled';
import { StyledTableCells } from '../../Admin/admin-tables-styled';
import { StylesCss } from '../../Assignments/Assignments.styled';
import { CountTooltip } from '../../Assignments/AssignmentsTable/List';
import { toReadableFormat } from '../../../utils/dateFormat';
import {
  PAGE_SIZE,
  READABLE_SHORT_DATE_OPTIONS,
  SubmissionTypes,
} from '../../../variables/constant';
import useTeacherBenchmarksList from './teacher-benchmarks-list-hook';
import { GradedByOptions } from '../../AdminBenchmarks/Create/Steps/DetailsStep';
import FlyoverHeader, {FlyoverHeaderColor} from "../../../components/FlyoverHeader";
import FlyoverDrawer from "../../../components/FlyoverDrawer";
import FlyoverContainer from "../../../components/FlyoverContainer";

const BenchmarkTable = () => {
  const theme = useTheme();
  const styles = StylesCss(theme);
  const {
    getBenchmarkTypeLabel,
    benchmarks,
    pagination,
    loading,
    totalCount,
    order,
    sort,
    onSortChange,
  } = useTeacherBenchmarksList();
  const [showSubmissions, setShowSubmissions] = useState<boolean>(false);
  const [benchmarkId, setBenchmarkId] = useState<string>('');
  const [submissionType, setSubmissionType] = useState<string>(SubmissionTypes.Submitted);
  const [submissionColor, setSubmissionColor] = useState<FlyoverHeaderColor>("success");

  const handleOpenSubmissions = (benchmarkId: string, type: string) => {
    setBenchmarkId(benchmarkId);
    setSubmissionType(type);

    switch (type) {
      case SubmissionTypes.Submitted:
        setSubmissionColor("success");
        break;
      case SubmissionTypes.InProgress:
        setSubmissionColor("warning");
        break;
      case SubmissionTypes.NotStarted:
        setSubmissionColor("error");
        break;
    }

    setShowSubmissions(true);
  };

  const handleCloseSubmissions = () => {
    setShowSubmissions(false);
  };

  const isTableEmpty = !loading && benchmarks?.length === 0;

  const handlePageChange = (
    _: React.ChangeEvent<unknown>,
    value: React.SetStateAction<number>,
  ) => {
    // @ts-ignore
    pagination.setPage(value);
  };

  const handleSort = (column: BenchmarkTeacherDtoSortType) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const getNoDataLabels = () => ({
    label: 'No Benchmarks Created Yet',
    description: 'There has yet to be a benchmark created at this time',
  });

  const { label, description } = getNoDataLabels();
  const orderByIcon = order === OrderBy.Desc ? 'asc' : 'desc';

  const sortColumn = (title: string, sortEnum: BenchmarkTeacherDtoSortType) => (
    <StyledTableSort
      direction={orderByIcon}
      onClick={handleSort(sortEnum)}
      active={sort === sortEnum}
      IconComponent={ArrowDownIcon}
    >
      { title }
    </StyledTableSort>
  );

  return (
    <Box
      display="grid"
      width="100%"
      mt={2}
      mb={2}
    >
      <Box>
        <StyledTableContainer>
          <Table
            stickyHeader
            aria-label="Teacher Benchmarks List"
            sx={{ ...((loading || isTableEmpty) && styles.tableWithNoData), ...(loading && { maxHeight: '60px' }) }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCells />
                <StyledTableCells sx={styles.width30}>
                  {sortColumn('Benchmark Title', BenchmarkTeacherDtoSortType.BenchmarkTitle)}
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  { sortColumn('Date', BenchmarkTeacherDtoSortType.StartDate) }
                </StyledTableCells>
                <StyledTableCells sx={styles.width15}>
                  {sortColumn('Benchmark Type', BenchmarkTeacherDtoSortType.BenchmarkType)}
                </StyledTableCells>
                <StyledTableCells sx={styles.width20}>
                  {sortColumn('Graded By', BenchmarkTeacherDtoSortType.GradedBy)}
                </StyledTableCells>
                <StyledTableCells sx={styles.width15}>
                  Submissions
                </StyledTableCells>
                <StyledTableCells />
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && benchmarks?.map((row) => (
                <TableRow key={row?.benchmark_id}>
                  {/*Image*/}
                  <TableCell>
                    <Box>
                      <ImageCardAssignment
                        imageSrc={row.resource?.file?.url || ''}
                        key={row?.benchmark_id}
                        thumbnailImageSrc={row.resource?.file?.thumbnail_url || row.resource?.file?.url || ''}
                        showFullscreenIcon
                      />
                    </Box>
                  </TableCell>

                  {/*Assignment Title*/}
                  <TableCell
                    sx={styles.assignmentNameCell}
                  >
                    {row.benchmark_title}
                  </TableCell>

                  {/*Date*/}
                  <TableCell
                    sx={{ ...styles.dateRangeCell, ...styles.uppercase }}
                  >
                    {`${toReadableFormat(row.start_date, READABLE_SHORT_DATE_OPTIONS)} - ${toReadableFormat(row.close_date, READABLE_SHORT_DATE_OPTIONS)}`}
                  </TableCell>

                  {/*Benchmark Type*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {getBenchmarkTypeLabel(row.benchmark_type)}
                  </TableCell>

                  {/*Graded By*/}
                  <TableCell
                    sx={styles.dateRangeCell}
                  >
                    {GradedByOptions.find(options => options.value === row.graded_by)?.label}
                  </TableCell>

                  {/*Submissions*/}
                  <TableCell
                    sx={styles.countCell}
                  >
                    <Box component="div" sx={styles.flexDesign}>
                      <CountTooltip
                        onClick={() => handleOpenSubmissions(row.benchmark_id, SubmissionTypes.Submitted)}
                        tooltipTitle={SubmissionTypes.Submitted}
                        count={row.status_aggregation?.Submitted ?? 0}
                      />
                      <CountTooltip
                        onClick={() => handleOpenSubmissions(row.benchmark_id, SubmissionTypes.InProgress)}
                        tooltipTitle={SubmissionTypes.InProgress}
                        count={row.status_aggregation?.InProgress ?? 0}
                      />
                      <CountTooltip
                        onClick={() => handleOpenSubmissions(row.benchmark_id, SubmissionTypes.NotStarted)}
                        tooltipTitle={SubmissionTypes.NotStarted}
                        count={row.status_aggregation?.NotStarted ?? 0}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {isTableEmpty && (
                <TableRow>
                  <TableCell
                    colSpan={9}
                    align="center"
                  >
                    <NoResults
                      label={label}
                      description={description}
                      svgComponent={<WavingLadyFrame />}
                    />
                  </TableCell>
                </TableRow>
              )}
              <Loader open={loading} />
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      {totalCount! > PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop="20px"
        >
          <TablePagination
            count={Math.ceil(totalCount! / PAGE_SIZE) || 0}
            page={pagination.page}
            onChange={handlePageChange}
            defaultPage={1}
          />
        </Box>
      )}
      <FlyoverDrawer open={showSubmissions} size={"small"} onClose={handleCloseSubmissions}>
        <FlyoverHeader title={submissionType} color={submissionColor} onClose={handleCloseSubmissions} subtitle={"2 Students"} />
        <FlyoverContainer>
          benchmarkId: {benchmarkId}
        </FlyoverContainer>
      </FlyoverDrawer>
    </Box>
  );
};

export default BenchmarkTable;
