import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import {
  TeacherBenchmarkSubmissionListSortType,
  OrderBy,
  useTeacherBenchmarkGradesListQuery,
  SubmissionStatus,
  useTeacherBenchmarkGradingQueueQuery, TeacherSubmissionListInput,
} from '../../../generated/graphql';
import usePagination from '../../../utils/usePagination';
import { PAGE_SIZE } from '../../../variables/constant';
import useBenchmarkTypes from '../../../components/BenchmarkTypes/benchmarkTypes-hook';
import useBenchmarksQueueHook from '../../ScoreTaskBenchmark/score-benchmark-queue-hook';

const useTeacherBenchmarksSubmissionList = ( ) => {
  const pagination = usePagination();
  const history = useHistory();
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, TeacherBenchmarkSubmissionListSortType.SubmittedAt));
  const [order, setOrder] = useQueryParam('order', withDefault(StringParam, OrderBy.Desc));
  const [selectedBenchmarkTypes, setSelectedBenchmarkTypes] = useState<[]>([]);
  const initialSubmissionStatus = [SubmissionStatus.Reviewed, SubmissionStatus.Submitted];
  const [selectedStatus, setSelectedStatus] = useState<SubmissionStatus | undefined>(undefined);
  const initialInputVariables = {
    grade_statuses: initialSubmissionStatus,
    limit: PAGE_SIZE,
    page: pagination.page,
    sort: TeacherBenchmarkSubmissionListSortType.SubmittedAt,
    order_by: order as OrderBy,
  };
  const [queryVariables, setQueryVariables] = useState<TeacherSubmissionListInput>({
    ...initialInputVariables,
  });
  const { initializeSubmissionQueue } = useBenchmarksQueueHook();

  const createQueue = (submissionQueueIDs: string[], submissionID: string) => {
    initializeSubmissionQueue(submissionQueueIDs, submissionID);
  };

  const { data, loading } = useTeacherBenchmarkGradesListQuery({
    fetchPolicy: 'network-only',
    variables: queryVariables,
  });

  const {
    loading: benchmarkTypesLoading,
    benchmarkTypes,
    getBenchmarkTypeLabel,
  } = useBenchmarkTypes();

  const { data: submissionQueueData, loading: submissionQueueDataLoading } = useTeacherBenchmarkGradingQueueQuery({
    fetchPolicy: 'network-only',
    variables: {
      ...queryVariables,
      limit: undefined,
      page: undefined,
    },
  });

  const benchmarkSubmissionList = data?.teacherBenchmarkSubmissionList?.nodes ?? [];
  const totalCount = data?.teacherBenchmarkSubmissionList?.node_count ?? 0;

  const onSortChange = (sortBy: TeacherBenchmarkSubmissionListSortType, orderBy: OrderBy) => {
    pagination.setPage(1);
    setSort(sortBy);
    setOrder(orderBy);
    setQueryVariables({
      ...queryVariables,
      sort: sortBy,
      order_by: orderBy,
    });
  };

  const handleSort = (column: TeacherBenchmarkSubmissionListSortType) => () => {
    if (column === sort) {
      onSortChange(column, order === OrderBy.Desc ? OrderBy.Asc : OrderBy.Desc);
    } else {
      onSortChange(column, OrderBy.Asc);
    }
  };

  const handleRedirection = (submissionQueueIDs: string[], submissionID: string) => async () => {
    createQueue(submissionQueueIDs, submissionID);
    if (submissionID) history.push(`/tasks/${submissionID}/benchmark/score`);
  };

  const handleApplyFilters = () => {
    pagination.setPage(1);
    setQueryVariables({
      limit: PAGE_SIZE,
      page: 1,
      sort: sort as TeacherBenchmarkSubmissionListSortType,
      order_by: order as OrderBy,
      ...(selectedBenchmarkTypes.length ? { benchmark_types: selectedBenchmarkTypes } : {}),
      ...(selectedStatus ? { grade_statuses: [selectedStatus] } : { grade_statuses: initialSubmissionStatus }),
    });
  };

  const handleClearFilters = async () => {
    pagination.setPage(1);
    setSelectedBenchmarkTypes([]);
    setSelectedStatus(undefined);
    setQueryVariables({ ...initialInputVariables, benchmark_types: undefined });
  };

  const handlePagination = (value: React.SetStateAction<number>) => {
    pagination.setPage(value as number);
    setQueryVariables({
      ...queryVariables,
      page: value as number,
    });
  };

  return {
    loading: loading || benchmarkTypesLoading || submissionQueueDataLoading,
    pagination,
    sort,
    order: order as OrderBy,
    onSortChange,
    handleSort,
    handleRedirection, handlePagination,
    selectedBenchmarkTypes, setSelectedBenchmarkTypes,
    selectedStatus, setSelectedStatus,
    totalCount,
    benchmarkSubmissionList,
    handleApplyFilters,
    handleClearFilters,
    benchmarkTypes,
    getBenchmarkTypeLabel,
    submissionQueueData: submissionQueueData?.teacherBenchmarkGradingQueue ?? [],
    createQueue,
  };
};

export default useTeacherBenchmarksSubmissionList;
